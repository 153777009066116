
<div class="container">
  <div class="row">
    <div class="col-lg-4"></div>
    <div class="col-lg-4" style="margin-top: 20%">
      <ul class="list-group">
        <li class="list-group-item d-flex zg_gray_bg">
          <div style="min-height: 140px" class="ml-auto mr-auto mt-4">
            <zagoHex ref="refZagoHex" @finish="logout()" />
          </div>
        </li>
        <li class="list-group-item">
          <h1>{{ info_title }}</h1>
          <h4>{{ info_text }}</h4>
        </li>
        <!-- li class="list-group-item" v-if="success || error" -->
        <!--/li-->
      </ul>
      <div v-if="error" class="alert alert-danger" role="alert">Logout fehlgeschlagen!</div>
      <div
        v-if="success"
        class="alert alert-success mb-0 animate__animated animate__flipInX mt-4"
        role="alert"
      >
        Logout durchgeführt.
      </div>
      <a
        href="/"
        v-if="success"
        class="btn btn-primary btn-block animate__animated animate__flipInX mt-4"
      >
        zum Start
      </a>

      <a href="#" @click="location.reload()" v-if="error">wiederholen</a>
      <a href="/" v-if="!success">Logout abbrechen</a>
    </div>
  </div>
</div>
