<script>
import Cookies from 'js-cookie';
import ZagoHex from './zago_hex.vue';

export default {
  components: {
    ZagoHex,
  },
  props: {
    logout_url: {
      default: '/auth/sign_out',
    },
  },
  data() {
    return {
      show: true,
      value: 0,
      max: 100,
      data: {
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      success: false,
      error: false,
      info_title: 'Tschüß',
      info_text: 'Abmeldung erfolgt in Kürze.',
    };
  },
  mounted() {
    if (this.$route.query.ref) {
      if (this.$route.query.ref == '401') {
        this.info_title = 'Huch!';
        this.info_text = 'Die Sitzung ist abgelaufen.';
      }
    }
    this.$refs.refZagoHex.load();
  },
  methods: {
    logout() {
      var data = { authenticity_token: this.data.csrf };
      this.$http.delete(this.logout_url, { body: data }).then(
        (res) => {
          if (res.body) {
            if (res.body.ok == true) {
              Cookies.remove('zxdata');
              this.success = true;
              setTimeout(
                function () {
                  location.href = '/';
                }.bind(this),
                1000,
              );
            } else {
              this.error = true;
            }
          } else {
            this.error = true;
          }
        },
        (res) => {
          if (res.body) {
            if (res.body.error) this.errors = [res.body.error];
            if (res.body.errors) this.errors = res.body.errors;
          } else {
            alert('Error.');
          }
        },
      );
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-4"></div>
      <div class="col-lg-4" style="margin-top: 20%">
        <ul class="list-group">
          <li class="list-group-item d-flex zg_gray_bg">
            <div style="min-height: 140px" class="ml-auto mr-auto mt-4">
              <zagoHex ref="refZagoHex" @finish="logout()" />
            </div>
          </li>
          <li class="list-group-item">
            <h1>{{ info_title }}</h1>
            <h4>{{ info_text }}</h4>
          </li>
          <!-- li class="list-group-item" v-if="success || error" -->
          <!--/li-->
        </ul>
        <div v-if="error" class="alert alert-danger" role="alert">Logout fehlgeschlagen!</div>
        <div
          v-if="success"
          class="alert alert-success mb-0 animate__animated animate__flipInX mt-4"
          role="alert"
        >
          Logout durchgeführt.
        </div>
        <a
          href="/"
          v-if="success"
          class="btn btn-primary btn-block animate__animated animate__flipInX mt-4"
        >
          zum Start
        </a>

        <a href="#" @click="location.reload()" v-if="error">wiederholen</a>
        <a href="/" v-if="!success">Logout abbrechen</a>
      </div>
    </div>
  </div>
</template>
