<script>
  export default {
    props: {
      value: {
        type: Array,
        required: false,
        default: () => []
      },
    },
  }
</script>

<template>
    <b-form-invalid-feedback :state="(value.length==0)">
      <span v-for="(item, index) in value" :key="index">
        {{ item }}<br/>
      </span>
    </b-form-invalid-feedback>
</template>
