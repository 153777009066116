
<form @submit="onSubmit">
  <div class="container">
    <div class="row">
      <div class="col-lg-6" style="margin-top: 10%">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group row">
              <label for="title" class="col-md-3 col-form-label"> </label>
              <div class="col-md-9">
                <h1 v-if="!page.app || !page.app.name">{{ page.title }}</h1>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group row">
              <label for="article" class="col-md-3 col-form-label"> </label>
              <div class="col-md-9">
                <div v-if="page.app && page.app.name" class="card mb-2" tag="article">
                  <img
                    v-if="page.app.image"
                    :src="page.app.image"
                    class="card-img-top"
                    :alt="page.app.name"
                  />
                  <div class="card-body">
                    <h5 class="card-title">{{ page.app.name }}</h5>
                    <p class="card-text">{{ page.app.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group row">
              <label for="email" class="col-md-3 col-form-label">Email</label>
              <div class="col-md-9">
                <input
                  id="email"
                  class="form-control"
                  v-model="data.email"
                  data-test="email"
                  autofocus
                />
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group row">
              <label for="password" class="col-md-3 col-form-label">Password</label>
              <div class="col-md-9">
                <input
                  id="password"
                  type="password"
                  class="form-control"
                  v-model="data.password"
                  data-test="password"
                  @keydown.enter="onSubmit"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group row">
              <label for="password" class="col-md-3 col-form-label"> </label>
              <div class="col-md-9">
                <button
                  id="login"
                  type="button"
                  class="btn btn-primary"
                  data-test="login"
                  @click="onSubmit"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group row">
              <label for="message" class="col-md-3 col-form-label"> </label>
              <div class="col-md-9">
                <div
                  class="alert alert-danger"
                  v-for="(item, index) in errors"
                  v-bind:key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
