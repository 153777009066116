
const state = () => ({
    tabLevel: 0,
});

const mutations = {
    raiseTabLevel(state) {
        state.tabLevel++;
    },

    reduceTabLevel(state) {
        state.tabLevel--;
    }
};

const getters = {
    getTabLevel: (state) => {
        return state.tabLevel;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};
