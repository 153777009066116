import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

export default function http() {
  Vue.http.interceptors.push(function (request, next) {
    next(function (response) {
      // Beim Login nicht weiterleiten
      if (
        this.$router.currentRoute.fullPath == '/login' ||
        this.$router.currentRoute.name == 'ZxLogin'
      ) {
        return;
      }

      // nicht gefunden
      if (response.status == 404) {
        this.$router.go({ name: 'ZxNotFound' });
        return;
      }

      // nicht angemeldet - weiterleiten Zum Login oder
      // Testen ob der Cookie _zx_multi_session vorhanden ist - dann zum Logout weiterleiten
      if (response.status == 401) {
        if (document.cookie.indexOf('zxdata') > -1) {
          window.location.href = '/logout?ref=401';
          return;
        }
        this.$router.go({ name: 'ZxLogin' });
        return;
      }

      if (response.status != 200) {
        var body = response.body;
        if (response.body !== undefined && response.body.message) body = response.body.message;
        var options = {
          id: 'zx-page-toast',
          title: response.status,
          body: body,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full',
          autoHideDelay: 5000,
          href: process.env.NODE_ENV !== 'production' ? '/__better_errors' : null,
          noCloseButton: false,
        };
        if (response.status == 405) {
          var add = {
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            noCloseButton: true,
            href: null,
          };
          options = Object.assign(options, add);
        }
        this.$bvToast.toast(body, options);
        this.alert = [];
      }
    });
  });
}
