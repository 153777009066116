
<div class="container">
  <div class="row">
    <div class="col-lg-2">
    </div>
    <div class="col-lg-6" style="margin-top: 30%;">
      <h1>Forbidden</h1>
      <ul>
        <li>
          <a href="#" @click="goBack">go back</a>
        </li>
        <li>
          <a href="/">go to start</a>
        </li>
        <li>
          <a href="/logout">go to logout</a>
        </li>
      </ul>
    </div>
  </div>
</div>
