const state = () => ({
    open: false
});

const mutations = {
  setOpenState(state, obj){
    state.open = obj;
  },
};

const getters = {
  openState: (state) => {
    return state.open
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
