<script>
export default {
  props: {
    timerWait: {
      default: 300,
    }
  },
  data() {
    return {
      timer: null,
      counter: 10,
    };
  },
  methods: {
    step_timer() {
      var num = this.counter;
      var el = 'bl' + num;
      var obj = document.getElementById(el);
      var move = 'animate__bounceIn';
      if (num < 5) move = 'animate__rotateIn';
      if (num < 3) move = 'animate__fadeInTopRight';
      if (obj) {
        obj.classList.add(move);
      }
      if (num == 1) {
        clearInterval(this.timer);
        this.$emit('finish');
      }
    },
    load() {
      this.timer = setInterval(() => {
        this.counter = this.counter - 1;
        this.step_timer();
      }, this.timerWait);
    },
  },
};
</script>

<template>
  <div class="hex2-container">
    <div class="hex2-row">
      <div class="hex2 first animate__animated red animate__slower" style="opacity: 0" id="bl3">
        <div class="left"></div>
        <div class="middle"></div>
        <div class="right"></div>
      </div>
      <div class="hex3 animate__animated animate__delay-2s red" style="opacity: 0" id="bl2">
        <div class="left"></div>
        <div class="middle"></div>
        <div class="right"></div>
      </div>
      <div class="hex4 animate__animated animate__delay-2s red" style="opacity: 0" id="bl1">
        <div class="left"></div>
        <div class="middle"></div>
        <div class="right"></div>
      </div>
    </div>
    <div class="hex2-row">
      <div class="hex2 animate__animated animate__slower blue" style="opacity: 0" id="bl9">
        <div class="left"></div>
        <div class="middle"></div>
        <div class="right"></div>
      </div>
      <div class="hex2 even animate__animated red animate__slower" style="opacity: 0" id="bl4">
        <div class="left"></div>
        <div class="middle"></div>
        <div class="right"></div>
      </div>
      <div class="hex2 animate__animated animate__slower blue" style="opacity: 0" id="bl8">
        <div class="left"></div>
        <div class="middle"></div>
        <div class="right"></div>
      </div>
    </div>
    <div class="hex2-row">
      <div class="hex2 animate__animated animate__slower blue" style="opacity: 0" id="bl7">
        <div class="left"></div>
        <div class="middle"></div>
        <div class="right"></div>
      </div>
      <div class="hex2 even animate__animated animate__slower blue" style="opacity: 0" id="bl5">
        <div class="left"></div>
        <div class="middle"></div>
        <div class="right"></div>
      </div>
      <div class="hex2 animate__animated animate__slower blue" style="opacity: 0" id="bl6">
        <div class="left"></div>
        <div class="middle"></div>
        <div class="right"></div>
      </div>
    </div>
  </div>
</template>
