const state = () => ({
  data: {
    ts: null,
    perm: null,
    avatar: null,
  },
});

const mutations = {
  setData(state, obj) {
    state.data = obj;
  },
};

const getters = {
  getData: (state) => {
    return state.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
