<script>
export default {
  name: 'zxform',
  props: {
    app: {
      default: '',
    },
    page_url: {
      default: '/page',
    },
    login_url: {
      default: '/auth/sign_in',
    },
  },
  data() {
    return {
      data: {
        email: '',
        password: '',
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      page: [],
      errors: [],
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.errors = [];
      if (this.data.email.length < 3 || this.data.password.length < 3) {
        this.errors.push('Invalid login credentials. Please try again.');
        return false;
      }
      var data = {
        user: {
          email: this.data.email,
          password: this.data.password,
        },
        authenticity_token: this.data.csrf,
      };
      this.$http.post(this.login_url, data).then(
        (res) => {
          // Login OK
          if (res.body) {
            if (res.body.ok) {
              this.login(res);
            }
          } else {
            this.error = true;
          }
        },
        (res) => {
          // Fehler oder Fail
          if (res.body) {
            if (res.body.error) this.errors = [res.body.error];
            if (res.body.errors) this.errors = res.body.errors;
          } else {
            alert('Error.');
          }
        },
      );
    },
    login(res) {
      // der Crsf-Token wird bei jedem Login neu generiert
      if (res.body.crsf) {
        document.querySelector('meta[name="csrf-token"]').setAttribute('content', res.body.crsf);
      }
      // Weiterleitung via $route.params.next
      if (this.$route.params.next) {
        this.$router.push({ path: this.$route.params.next });
        return;
      }
      // Weiterleitung im Result
      if (res.body.redirect != null) {
        this.$router.push({ path: res.body.redirect });
        return;
      }
      // Keine Weiterleitung bekommen - gehe zu Home
      if (this.$router.resolve('ZxHome')) {
        this.$router.push({ name: 'ZxHome' });
      } else {
        window.location.href = '/';
      }
    },
  },
  mounted() {
    var data = {
      page: 'login',
      app: this.app,
      authenticity_token: this.data.csrf,
    };
    this.$http.post(this.page_url, data).then((res) => {
      if (res.body) {
        if (res.body.page) this.page = res.body.page;
      } else {
        this.error = true;
      }
    });
  },
};
</script>

<template>
  <form @submit="onSubmit">
    <div class="container">
      <div class="row">
        <div class="col-lg-6" style="margin-top: 10%">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group row">
                <label for="title" class="col-md-3 col-form-label"> </label>
                <div class="col-md-9">
                  <h1 v-if="!page.app || !page.app.name">{{ page.title }}</h1>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group row">
                <label for="article" class="col-md-3 col-form-label"> </label>
                <div class="col-md-9">
                  <div v-if="page.app && page.app.name" class="card mb-2" tag="article">
                    <img
                      v-if="page.app.image"
                      :src="page.app.image"
                      class="card-img-top"
                      :alt="page.app.name"
                    />
                    <div class="card-body">
                      <h5 class="card-title">{{ page.app.name }}</h5>
                      <p class="card-text">{{ page.app.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group row">
                <label for="email" class="col-md-3 col-form-label">Email</label>
                <div class="col-md-9">
                  <input
                    id="email"
                    class="form-control"
                    v-model="data.email"
                    data-test="email"
                    autofocus
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group row">
                <label for="password" class="col-md-3 col-form-label">Password</label>
                <div class="col-md-9">
                  <input
                    id="password"
                    type="password"
                    class="form-control"
                    v-model="data.password"
                    data-test="password"
                    @keydown.enter="onSubmit"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group row">
                <label for="password" class="col-md-3 col-form-label"> </label>
                <div class="col-md-9">
                  <button
                    id="login"
                    type="button"
                    class="btn btn-primary"
                    data-test="login"
                    @click="onSubmit"
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group row">
                <label for="message" class="col-md-3 col-form-label"> </label>
                <div class="col-md-9">
                  <div
                    class="alert alert-danger"
                    v-for="(item, index) in errors"
                    v-bind:key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
