import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import setting from './modules/setting';
import sidebar from './modules/sidebar';
import tab from './modules/tab';
import Cookies from 'js-cookie';

Vue.use(Vuex);

const ZX_KEY = 'zxkey';

const COOKIE_EXPIRY = 1 * 5;

export default new Vuex.Store({
  modules: {
    auth,
    sidebar,
    tab,
    setting,
  },
  state: {
    keystore: {},
  },
  getters: {
    getByKey: (state) => (key) => {
      var data = state.keystore[key];
      /*
      if (typeof data == 'undefined') {
        try {
          var cookie_obj = JSON.parse(Cookies.get(ZX_KEY));
          if (cookie_obj) var data = cookie_obj[key];
        } catch (e) {
          data = null;
        }
      }*/
      return data;
    },
  },
  mutations: {
    setByKey(state, obj) {
      var key = obj.key;
      var value = obj.value;
      /*
      try {
        var cookie_obj = JSON.parse(Cookies.get(ZX_KEY));
        if (typeof cookie_obj == 'undefined') cookie_obj = {};
      } catch (e) {
        cookie_obj = {};
      }
      cookie_obj[key] = value;
      Cookies.set(ZX_KEY, cookie_obj, { expires: COOKIE_EXPIRY, sameSite: 'strict' });
      */
      state.keystore[key] = value;
    },
  },
});
