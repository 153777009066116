import Cookies from 'js-cookie';

// Cookienames
const ZX_DATA = 'zxdata';

// Cookie-expiry
// 1 (One day) * 5  -> 5 days
const COOKIE_EXPIRY = 1 * 5;

const state = () => ({
  data: Cookies.get(ZX_DATA) != null ? JSON.parse(Cookies.get(ZX_DATA)) : {},
});

const mutations = {
  setData(state, data) {
    state.data = data;
    Cookies.set(ZX_DATA, data, { expires: COOKIE_EXPIRY, sameSite: 'strict' });
  },

  clean(state) {
    state.data = {};
    Cookies.set(ZX_DATA, state.data, { expires: COOKIE_EXPIRY, sameSite: 'strict' });
  },

  update(state) {
    var data = Cookies.get(ZX_DATA) != null ? JSON.parse(Cookies.get(ZX_DATA)) : {};
    state.data = data;
    //console.log('Update Token in auth');
  },
};

const getters = {
  getData: (state) => {
    return state.data;
  },

  checkAuth: (state) => {
    var ret = false;
    if (state.data && state.data.auth) {
      var auth = state.data.auth;
      ret =
        auth.token != null &&
        auth.client != null &&
        auth.expiry != null &&
        auth.uid != null &&
        auth.token != '' &&
        auth.client != '' &&
        auth.expiry != '' &&
        auth.uid != '';
    }
    return ret;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
