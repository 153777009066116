import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from 'js-cookie';

Vue.use(VueRouter);

export default function routerBeforeEachSetup(router) {
    router.beforeEach((to, from, next) => {
        
        if (to.path == '/') return next();

        try {
            var cookie_obj = JSON.parse(Cookies.get('zxdata'));
        } catch (e) {
            var cookie_obj = null;
        }

        var is_login = ((cookie_obj) && (!!cookie_obj['email']));

        // console.log('cookie_obj', cookie_obj);
        // console.log('is_login', is_login);

        if (is_login == false) {
            // Logout-Page not required
            if (to.name === 'ZxLogout') return next({ name: 'ZxHome' });

            // Allow login and register pages
            var allow = ['ZxLogin', 'ZxRegister'];
            if (allow.indexOf(to.name) === -1) {
                return next({ name: 'ZxLogin', params: { next: to.path } });
            }
        }

        // Login-Page not required
        if (is_login == true && to.name === 'ZxLogin') {
            return next({ name: 'ZxHome' });
        }

        // Page requires permissions
        if (to.meta.requiresPerm != null) {
            if (is_login == true) {
                var perm = cookie_obj['perm'];
                if (perm == null) {
                    return next({ name: 'ZxSetup', params: { next: to.path }, });
                } else if (perm.includes(to.meta.requiresPerm)) {
                    return next();

                } else {
                    return next({ name: 'ZxForbidden' });
                }
            } else {
                return next({ name: 'ZxLogin', params: { next: to.path }, });
            }
        } else {
            return next();
        }
    });
}
